import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Header from '../components/Header';
import BrowserFrame from '../components/BrowserFrame';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import FeatureSection from '../components/sections/FeatureSection';
import RestLg from '../components/icons/RestLg';
import WebhookLg from '../components/icons/WebhookLg';
import Container from '../components/Container';

const DevelopersPage = () => (
  <Layout>
    <Seo title="Subscribe Pro Subscription Commerce Platform Features for Developers" />

    <Header graphic={2}>For Developers</Header>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="prose prose-lg max-w-none prose-headings:font-normal prose-h2:text-4xl prose-h3:text-3xl">
          <h2>Developer Resources</h2>
          <ul>
            <li>
              API and Developer Docs -{' '}
              <a href="https://docs.subscribepro.com/technical/rest-api/">https://docs.subscribepro.com/technical/rest-api/</a>
            </li>
            <li>
              Subscribe Pro GitHub Page - <a href="https://github.com/subscribepro">https://github.com/subscribepro</a>
            </li>
            <li>
              Customer Support Portal - <a href="http://support.subscribepro.com/">http://support.subscribepro.com/</a>
            </li>
          </ul>
          <p></p>
        </div>
      </Container>
    </section>

    <FeatureSection
      title="Fully-Featured but Flexible Integrations"
      imageContent={
        <BrowserFrame className="max-w-[900px]">
          <StaticImage
            className="object-contain "
            src="../images/docs_page_ecommerce_integrations.png"
            width={900}
            quality={95}
            objectFit="contain"
            alt="Subscribe Pro Documentation | eCommerce Platform Integrations"
          />
        </BrowserFrame>
      }
      imageLeftOrRight="left"
    >
      <p className="mt-4">
        Subscribe Pro provides eCommerce platform integrations for major platforms. Our integrations are fully featured out of the
        box, but designed with customization in mind. We have extensively documented and the standard and customizable components
        of our integrations and our support team is always here to help you with your implementation.
      </p>
    </FeatureSection>

    <FeatureSection
      title="Powerful and Flexible APIs"
      imageContent={<RestLg className="w-[16rem] h-auto" />}
      imageLeftOrRight="right"
    >
      <p className="mt-4">
        We offer APIs covering all features available in the Subscribe Pro platform. The Subscribe Pro API is a standard
        REST-style API that accepts and returns JSON. We are committed to making sure all data stored by our subscription commerce
        platform is available via API and that all operations may be completed via API as well.
      </p>

      <p className="mt-4">
        We have architected our platform to provide a solid foundation for the long term. Our support team is never more than a
        phone call away. And when you need our help, we strive to provide truly world class support.
      </p>
    </FeatureSection>

    <FeatureSection title="Webhooks" imageContent={<WebhookLg className="w-[14rem] h-auto" />} imageLeftOrRight="left">
      <p className="mt-4">
        Subscribe Pro publishes webhooks that enable your application to be notified of key events affecting your customers (for
        example a customer cancels their subscription). We publish webhooks for all major events in our platform.
      </p>

      <p className="mt-4">
        Webhooks are reattempted when not handled successfully with a progressive back-off. Logs for all webhook sends are
        available in our Merchant App. And Webhooks can be replayed easily.
      </p>

      <p className="mt-4">
        Clients use our webhooks to integrate with third party systems for reporting, transactional email notifications, marketing
        features and many creative applications.
      </p>
    </FeatureSection>

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default DevelopersPage;
