import React from 'react';

const WebhookLg = ({ className }) => (
  <svg className={className} viewBox="0 0 149 149" fill="none">
    <g clipPath="url(#clip0_48_3508)">
      <g styles={{ mixBlendMode: 'multiply' }}>
        <ellipse cx="77.0019" cy="40.3581" rx="41.9408" ry="40.3581" fill="#C2EDE0" />
      </g>
      <path
        d="M77.6723 35.2782C81.6102 35.2782 84.8025 32.0869 84.8025 28.1502C84.8025 24.2135 81.6102 21.0222 77.6723 21.0222C73.7343 21.0222 70.542 24.2135 70.542 28.1502C70.542 32.0869 73.7343 35.2782 77.6723 35.2782Z"
        fill="black"
      />
      <path
        d="M77.6724 28.1499L133.722 109.09C136.026 107.493 138.822 106.558 141.845 106.558C149.717 106.558 156.105 112.944 156.105 120.814C156.105 128.683 149.717 135.07 141.845 135.07C137.156 135.07 132.992 132.811 130.391 129.31"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M20.7331 127.999C24.671 127.999 27.8633 124.807 27.8633 120.871C27.8633 116.934 24.671 113.743 20.7331 113.743C16.7951 113.743 13.6028 116.934 13.6028 120.871C13.6028 124.807 16.7951 127.999 20.7331 127.999Z"
        fill="black"
      />
      <path
        d="M20.7327 120.871L70.1882 40.3307C67.8038 38.8595 65.8188 36.6697 64.6095 33.9098C61.4494 26.702 64.7464 18.2853 71.9565 15.1375C79.1666 11.9784 87.5861 15.2744 90.7348 22.4822C92.1494 25.6984 92.2635 29.1654 91.328 32.2903"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M141.914 127.998C145.852 127.998 149.044 124.807 149.044 120.87C149.044 116.934 145.852 113.742 141.914 113.742C137.976 113.742 134.783 116.934 134.783 120.87C134.783 124.807 137.976 127.998 141.914 127.998Z"
        fill="black"
      />
      <path
        d="M141.913 120.87L35.4497 120.813C35.4497 123.619 34.6283 126.447 32.9056 128.922C28.4221 135.389 19.5349 136.997 13.0664 132.515C6.59778 128.033 4.98919 119.148 9.47271 112.682C12.1423 108.827 16.3748 106.694 20.7328 106.557"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_48_3508">
        <rect width="163.034" height="149.01" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WebhookLg;
